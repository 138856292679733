import { Text, Heading, Img } from "./..";
import React from "react";

export default function UserProfile({
  userImage = "images/img_person_light_sk.svg",
  userStatistic = "124,513",
  userStatisticDescription = "Total Signups",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex justify-center items-start w-[24%] md:w-full p-[0.50rem] border-black-900_26 border border-solid bg-white-a700_01 rounded-md`}
    >
      <Img src={userImage} alt="Profile Image" className="h-[2.13rem] w-[2.13rem]" />
      <div className="flex flex-1 flex-col items-end justify-center gap-[0.38rem] self-center">
        <Heading size="headinglg" as="h3">
          {userStatistic}
        </Heading>
        <Text as="p" className="!text-[0.81rem] !font-medium !text-black-900_99">
          {userStatisticDescription}
        </Text>
      </div>
    </div>
  );
}

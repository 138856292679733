import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import AdminLogin from "pages/AdminLogin";
import Admindashboard from "pages/Admindashboard";
import AdminFuelPrices from "pages/AdminFuelPrices";
import AdminAllFeedbacks from "pages/AdminAllFeedbacks";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <AdminLogin/> },
    // { path: "*", element: <NotFound /> },
    {
      path: "adminlogin",
      element: <AdminLogin />,
    },
    {
      path: "admindashboard",
      element: <Admindashboard />,
    },
    {
      path: "adminfuelprices",
      element: <AdminFuelPrices />,
    },
    {
      path: "adminallfeedbacks",
      element: <AdminAllFeedbacks />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
